<template>

        <div v-if="article" class="article article--single" :class="{'article--news': article.pid===4,'article--bulletin': article.pid===9 }">
            <figure class="article-banner" v-if="article.images.length" @click.exact="toggleLightbox">
                <slider ref="bannerSlider" :controls="true" :pagination="true" layout="controlsinview" :gap="0" v-if="(article.images.length + article.youtube.length) > 1">
                    <Youtube class="block-video" v-for="(yt,idx) in article.youtube" :key="'yt_'+idx" :vars="{showinfo : false, autoplay: false, modestbranding: true, origin: 'wirsindnew.neustadt.de'}" :src="yt"
                             ref="youtube" />
                    <img v-for="(img,idx) in article.images"
                         :key="'img_' + idx"
                         :src="img.url"
                         :srcset="img.urlLarge + ' 1200w'"
                         alt="" />
                </slider>
                <img v-else
                     :src="article.images[0].url"
                     :srcset="article.images[0].url + ' 500w, '+article.images[0].urlLarge + ' 800w'"
                     alt="" />
            </figure>
            <figure v-else>
                <Youtube class="block-video" v-for="(yt,idx) in article.youtube" :key="'yt_'+idx" :vars="{showinfo : false, autoplay: false, modestbranding: true, origin: 'wirsindnew.neustadt.de'}" :src="yt"
                         ref="youtube" />
            </figure>

            <Lightbox :is-visible="showLightbox" @close="showLightbox=false">
                <template v-slot:content>
                    <figure v-if="article.images.length">
                        <slider ref="lightboxSlider" :controls="true" :pagination="true" layout="controlsinview" :gap="0" v-if="(article.images.length + article.youtube.length) > 1">
                            <Youtube class="block-video" v-for="(yt,idx) in article.youtube" :key="'yt_'+idx" :vars="{showinfo : false, autoplay: false, modestbranding: true, origin: 'wirsindnew.neustadt.de'}" :src="yt"
                                     ref="youtube" />
                            <img v-for="(img,idx) in article.images"
                                 :key="'img_' + idx"
                                 :src="img.urlOrig"
                                 alt="" />
                        </slider>
                        <img v-else
                             :src="article.images[0].urlOrig"
                             alt="" />
                    </figure>
                </template>
            </Lightbox>

            <div class="article-header">
                <h1 class="article-title" v-html="article.title"></h1>
                <div class="meta">
                    <p v-show="article.categories.length"><span class="category" v-for="category in article.categories" :key="category.uid">{{ category.title }}</span></p>
                    <span>{{ $filters.formatDate(article.datetime, 'j.m.Y') }}</span>
                    <div v-if="isEditable" class="article-actions" >
                        <span class="edit" @click.stop="showForm = true">
                            <inline-svg :src="require('@/assets/images/ic_edit.svg')" height="12" />
                        </span>
                        <span class="delete" @click.stop="deleteArticle">
                            <inline-svg :src="require('@/assets/images/ic_delete.svg')" height="12" />
                        </span>
                        <ConfirmDialog ref="confirmDialog"/>
                    </div>
                </div>
            </div>

            <p class="article-intro" v-html="article.teaser" v-if="article.teaser"></p>
            <div class="article-content" v-html="article.bodytext" v-if="article.bodytext"></div>
            <p v-if="article.price">
                <strong>{{$t('price')}}:</strong>&nbsp;{{article.price.toLocaleString('de',{style:'currency',currency:'EUR'})}}
            </p>
            <p class="article-footer" v-if="article.author.uid">
                <router-link :to="{path:'/profil/' + article.author.uid }">{{ article.author.name }}</router-link>
            </p>
            <router-link :to="backLink" class="backlink">
                <inline-svg class="icon" :src="require('@/assets/images/ic_close.svg')" width="20" height="20" />
            </router-link>

            <Teleport to="#sidebar">
                <KappNewsComment v-if="article" :comments="article.comments" :newsid="article.uid" />
            </Teleport>

            <Lightbox v-if="isEditable" :is-visible="showForm" @close="showForm = false">
                <template v-slot:content>
                    <PostsForm :data="article" :doktype="article.doktype" action="update" />
                </template>
            </Lightbox>

        </div>
</template>

<script>

// import apiCall, { ApiDefaults } from '../../utils/api_call';
import KappNewsComment from '../../components/Blocks/KappNewsComment';
import Youtube from 'vue3-youtube';
import { mapGetters } from 'vuex';
import {defineAsyncComponent} from "vue";
import Slider from  '../../components/Subparts/Slider';

import { DELETE_ARTICLE } from '../../utils/store/actions/general';

export default {
	name: "NewsSingle",
	components: {
		KappNewsComment,
		Youtube,
		Slider,
		ConfirmDialog : defineAsyncComponent(()=>import('@/components/Plugins/ConfirmDialog')),
		Lightbox : defineAsyncComponent(()=>import('@/components/Plugins/Lightbox.vue')),
		PostsForm : defineAsyncComponent(()=>import('@/pages/Posts/KappPostsNew.vue'))
	},
	data() {
		return {
			article: false,
            showForm: false,
			showLightbox: false
		};
	},
	computed: {
		newsType: function(){
			let type = 1;
			switch( this.$route.name ) {
                case 'Beitrag': type = 4; break;
                case 'Media': type = 5; break;
            }
			return type;
        },
		backLink: function () {
            let backLinks = {
				4 : {name:'Posts'},
                1 : {name:'Main'},
                5: {name:'Mediathek'}
            };
			return backLinks[ this.newsType ];
		},
		isEditable: function ( ){
			return this.isAdmin || ( this.article.author && this.article.author.uid === this.getProfile.uid );
		},
		gallery: function () {
			return this.article.images.filter(( img ) => img.props.showinpreview === 0);
		},
        ...mapGetters(['getProfile','getBulletin','getNews','isAdmin','getMediaPost'])
	},
	methods: {
		toggleLightbox: function(){

			this.showLightbox = !this.showLightbox;

			if( typeof this.$refs.bannerSlider !== 'undefined'){
				this.$refs.lightboxSlider.activeIdx = this.$refs.bannerSlider.activeIdx;
				this.$refs.lightboxSlider._goTo( false );
			}
        },
		async deleteArticle () {
			const ok = await this.$refs.confirmDialog.show({
                title: 'Beitrag löschen',
                message: 'Wollen Sie den Beitrag wirklich löschen?',
                okButton: 'Ja, löschen!',
            })

            if( ok ) {
				let target;
				switch ( this.article.doktype ) {
					case 0 :
						target = 'news';
						break;
					case 3 :
						target = 'event';
						break;
					case 4 :
						target = 'bulletin';
						break;
				}

				this.$store.dispatch(DELETE_ARTICLE, {uid:  this.article.uid, target: target }).then(() => {
					this.article = false;
					this.$router.push(this.backLink);
				})
			}
        },
		fetchData: function () {

			let newsId = this.$route.params.id;
            if( this.$route.name === 'Beitrag' ) {
				this.article = this.getBulletin(newsId);
			}
			else if( this.$route.name === 'Media' ) {
				this.article = this.getMediaPost(newsId);
            }
			else {
				this.article = this.getNews(newsId);
			}
		}
	},
	created() {
		this.fetchData();
	}
};
</script>
<style scoped>
</style>
